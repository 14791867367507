import { 
  MapPageListingResponse,
  SortBy, 
  MapPageClusterRequest, 
  MapPageFilter,
} from '@zoocasa/go-search';
import { MapPageBoundarySearchRequest, MapPageBoundarySearchResponse } from '@zoocasa/go-search/dist/map_page';
import { fetchWithRetry } from 'utils/fetchWithRetry';
import { FetchOptions } from 'types/fetchWithRetry';
import { HttpRequestMethodTypes } from 'types';
import { getGoSearchHost, isServerSide } from 'utils/host-config';
import { MAP_PAGE_CONSTANTS } from 'constants/pagination';

export async function fetchClusters(filter: MapPageFilter, sortBy: SortBy = SortBy.DateDesc, signal?: AbortSignal, size: number = MAP_PAGE_CONSTANTS.DEFAULT_CLUSTER_DATA_SIZE, host: string = getGoSearchHost(isServerSide())) {
  const clustersEndpointUrl = `${host}/api/map/clusters?limit=${size}`;
  let resp: MapPageListingResponse;

  try {
    const mapPageClusterRequest = MapPageClusterRequest.fromPartial({ sortBy: sortBy, filter });
    const requestBody = MapPageClusterRequest.encode(mapPageClusterRequest).finish();
    
    const options: FetchOptions = { method: HttpRequestMethodTypes.POST, body: requestBody, signal };
    const response = await fetchWithRetry(clustersEndpointUrl, options);
    
    if (response.ok) {
      const content = await response.blob(); 
      const buffer = await content.arrayBuffer();
      resp = MapPageListingResponse.decode(new Uint8Array(buffer));
    } else {
      console.error(`fetch clusters within boundary: ${response.status} ${response.statusText} ${response.type}`);
      resp = MapPageListingResponse.fromPartial({});
    }
  } catch (error: any) {
    if (error?.name === 'AbortError') {
      console.debug('fetch clusters within boundary request was aborted');
      throw error;
    } 
    console.error('failed to fetch map page clusters: %s', error);
    resp = MapPageListingResponse.fromPartial({});
  }
  
  return resp;
}

export async function fetchListings(filter: MapPageFilter, sortBy: SortBy = SortBy.DateDesc, page: string = '', signal?: AbortSignal, size: number = MAP_PAGE_CONSTANTS.DEFAULT_CLUSTER_DATA_SIZE, host = getGoSearchHost(isServerSide())) {  
  const boundaryEndpointUrl = `${host}/api/map/boundary?limit=${size}${page.length > 0 ? `&page=${page}` : ''}`;
  let resp: MapPageBoundarySearchResponse;

  try {
    const mapPageBoundarySearchRequest = MapPageBoundarySearchRequest.fromPartial({ sortBy: sortBy, filter });
    const requestBody = MapPageClusterRequest.encode(mapPageBoundarySearchRequest).finish();
    
    const options: FetchOptions = { method: HttpRequestMethodTypes.POST, body: requestBody, signal };
    const response = await fetchWithRetry(boundaryEndpointUrl, options);

    if (response.ok) {
      const content = await response.blob(); 
      const buffer = await content.arrayBuffer();
      resp = MapPageBoundarySearchResponse.decode(new Uint8Array(buffer));
    } else {
      console.error(`fetch listings within boundary: ${response.status} ${response.statusText} ${response.type}`);
      resp = MapPageBoundarySearchResponse.fromPartial({});
    }
  } catch (error: any) {
    if (error?.name === 'AbortError') {
      console.debug('fetch listings within boundary request was aborted');
      throw error;
    }
    console.error('failed to fetch listings within boundary: %s', error);
    resp = MapPageBoundarySearchResponse.fromPartial({});
  }
  
  return resp;
}