//#region Constants
const DEFAULT_PAGE_SIZE = 27;
const MAX_PAGE_COUNT = 200;
const DEFAULT_CLUSTER_DATA_SIZE = 24;
//#endregion

export const AREA_PAGE_CONSTANTS = Object.freeze({
  MAX_TOTAL_COUNT: MAX_PAGE_COUNT * DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_SIZE,
  MAX_PAGE_COUNT,
});

export const MAP_PAGE_CONSTANTS = Object.freeze({
  DEFAULT_CLUSTER_DATA_SIZE,
});