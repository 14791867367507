import { Filter, AreaPageListing_Status, Range_Float, Range_DateMessage, AreaPageListing_ListingType, Range_Integer, PropertyCategories, TypeOfProperty, PropertyType, Style } from '@zoocasa/go-search';
import { NOT_AVAILABLE_SOLD_STATUS, NOT_AVAILABLE_STATUS, NOT_AVAILABLE_OTHER_STATUS } from 'contexts/preferences/listing-params';
import { PartialDeep } from 'type-fest';
import { SearchByAreaFilterType } from './types';

//#region Types
export type AreaFilterStrategy = (filter: PartialDeep<SearchByAreaFilterType>) => Filter;
//#endregion

function sharedFilterLogic(filter: PartialDeep<SearchByAreaFilterType>): Filter {
  let statusId: AreaPageListing_Status;
  if (filter.status === NOT_AVAILABLE_SOLD_STATUS) {
    statusId = AreaPageListing_Status.NotAvailableSold;
  } else if (filter.status === NOT_AVAILABLE_STATUS) {
    statusId = AreaPageListing_Status.NotAvailable;
  } else if (filter.status === NOT_AVAILABLE_OTHER_STATUS) {
    statusId = AreaPageListing_Status.NotAvailableOther;
  } else {
    statusId = AreaPageListing_Status.Available;
  }

  let price: Range_Float | undefined;
  if (filter.priceMax || filter.priceMin) {
    price = Range_Float.fromPartial({ gte: filter.priceMin || undefined, lte: filter.priceMax || undefined });
  }

  let listedDate: Range_DateMessage | undefined;
  if (filter.listedSince || filter.listedTo) {
    listedDate = Range_DateMessage.fromPartial({ gte: filter.listedSince ? new Date(filter.listedSince) : undefined, lte: filter.listedTo ? new Date(filter.listedTo) : undefined });
  }

  let squareFeet: Range_Float | undefined;
  if (filter.sqftMin || filter.sqftMax) {
    squareFeet = Range_Float.fromPartial({ gte:filter.sqftMin || undefined, lte: filter.sqftMax || undefined });
  }

  const filterArg: Filter = Filter.fromPartial({
    listingType: filter.rental ? AreaPageListing_ListingType.rent : AreaPageListing_ListingType.buy,
    statusId,
    price: price,
    squareFeet: squareFeet,
    hasGarage: filter.garage || false,
    hasPool: filter.pool || false,
    hasOpenHouse: filter.openHouse || false,
    hasWaterFront: filter.waterfront || false,
    hasFirePlace: filter.fireplace || false,
    hasLocker: filter?.additional?.condoOrTownhouse?.locker === 'yes',
    maintenance: filter?.additional?.condoOrTownhouse?.maintenanceFee,
    listedDate,
    bedroomsExact: undefined,
    bedroomsRange: undefined,
    bathroomsExact: undefined,
    bathroomsRange: undefined,
    parkingExact: undefined,
    parkingRange: undefined,
    hasImage: filter.hasImage,
  });

  if (filter?.bedrooms?.endsWith('+')) {
    filterArg.bedroomsRange = Range_Integer.fromPartial({ gte: Number(filter.bedrooms.replace('+', '')) });
  } else if (filter?.bedrooms) {
    filterArg.bedroomsExact = Number(filter.bedrooms);
  } else {
    filterArg.bedroomsRange = Range_Integer.fromPartial({ gte: 0 });
  }

  if (filter?.bathrooms?.endsWith('+')) {
    filterArg.bathroomsRange = Range_Integer.fromPartial({ gte: Number(filter.bathrooms.replace('+', '')) });
  } else if (filter?.bathrooms) {
    filterArg.bathroomsExact = Number(filter.bathrooms);
  } else {
    filterArg.bathroomsRange = Range_Integer.fromPartial({ gte: 0 });
  }

  if (filter?.parkingSpaces?.endsWith('+')) {
    filterArg.parkingRange = Range_Integer.fromPartial({ gte: Number(filter.parkingSpaces.replace('+', '')) });
  } else if (filter?.parkingSpaces) {
    filterArg.parkingExact = Number(filter.parkingSpaces);
  } else {
    filterArg.parkingRange = Range_Integer.fromPartial({ gte: 0 });
  }
  if (filter?.providerId && !isNaN(parseInt(filter.providerId))) {
    filterArg.providerId = parseInt(filter.providerId, 10);
  }

  return filterArg;
}

export const DefaultFilterStrategy: AreaFilterStrategy = filter => {
  const baseFilter = sharedFilterLogic(filter);

  const categories = Object.entries(filter?.homeType || {}).reduce((acc, [key, value]) => {
    if (value) {
      const category = key === 'house' ? 'House'
        : key === 'townhouse' ? 'Townhouse'
          : key === 'condo' ? 'Condo'
            : key === 'farm' ? 'Farm'
              : key === 'land' ? 'Land'
                : key === 'commercial' ? 'Commercial'
                  : key === 'houseDetached' ? 'House'
                    : key === 'houseSemidetached' ? 'House'
                      : key === 'houseAttached' ? 'House'
                        : 'Other';
      acc.push(category);
    }
    return acc;
  }, [] as string[]);

  const allPossibleCategories = ['House', 'Townhouse', 'Condo', 'Farm', 'Land', 'Commercial'];
  if (categories.length === 0 || allPossibleCategories.every(category => categories.includes(category))) {
    categories.push('Other');
  }

  const typeOfProperty = categories.reduce((acc, category) => {
    const categoryData = filter.rental
      ? PropertyCategories[category].rent
      : PropertyCategories[category].sale;

    acc.push(...categoryData.map(obj => ({
      propertyTypes: obj.types,
      propertySubTypes: obj.subTypes,
    })));
    return acc;
  }, [] as TypeOfProperty[]);

  return {
    ...baseFilter,
    typeOfProperty,
  };
};

export const LegacyFilterStrategy: AreaFilterStrategy = filter => {
  const baseFilter = sharedFilterLogic(filter);

  const styles = [];
  if (filter?.homeType?.condo) {
    styles.push(Style.condo_highrise, Style.condo_lowrise, Style.condo_new_development, Style.condo_other);
  }

  if (filter?.homeType?.townhouse) {
    styles.push(Style.townhouse);
  }

  if (filter?.homeType?.house) {
    styles.push(Style.house_attached, Style.house_detached, Style.house_semidetached, Style.house_new_development, Style.house_other, Style.multifamily_other);
  }

  if (!filter?.homeType?.condo && !filter?.homeType?.townhouse && !filter?.homeType?.house) {
    styles.push(Style.vacant_land, Style.other_unspecified);
  }

  if (filter?.homeType?.house && filter?.homeType?.farm && filter?.homeType?.land && filter?.homeType?.condo && filter?.homeType?.townhouse, filter?.homeType?.commercial) {
    styles.push(Style.All);
  }

  const propertyType = [];
  if (filter?.homeType?.house){
    propertyType.push(PropertyType.singleFamily, PropertyType.singleFamilyAndBasement, PropertyType.duplex, PropertyType.triplex, PropertyType.fourplex);
  }

  return {
    ...baseFilter,
    styles,
    propertyType,
  };
};