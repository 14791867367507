import {
  AreaPageListingRequest,
  AreaPageListingResponse,
  Filter,
  SortBy,
} from '@zoocasa/go-search';
import { fetchWithRetry } from 'utils/fetchWithRetry';
import { HttpRequestMethodTypes } from 'types';
import { AREA_PAGE_CONSTANTS } from 'constants/pagination';
import { getGoSearchHost, isServerSide } from 'utils/host-config';

export default async function fetchAreaPageListings(country: string, province: string, city: string, neighbourhood: string, sortBy: SortBy, filter: Filter, page: number, size: number = AREA_PAGE_CONSTANTS.DEFAULT_PAGE_SIZE, host: string = getGoSearchHost(isServerSide())) {
  const countrySegment = country?.length > 0 ? `/${country}` : '/all';
  const provinceSegment = province?.length > 0 ? `/${province}` : '';
  const citySegment = city?.length > 0 ? `/${city}` : '';
  const neighbourhoodSegment = neighbourhood?.length > 0 ? `/${neighbourhood}` : '';

  const limit = Math.max(size, 0);
  const offset = limit * (Math.max(page, 1) - 1);
  const areaPageUrl = `${host}/api/search${countrySegment}${provinceSegment}${citySegment}${neighbourhoodSegment}?limit=${limit}&offset=${offset}`;
  let resp: AreaPageListingResponse;
  try {
    const areaPageListingRequest = AreaPageListingRequest.fromPartial({ sortBy, filter });
    const requestBody = AreaPageListingRequest.encode(areaPageListingRequest).finish();
    const response = await fetchWithRetry(areaPageUrl, { method: HttpRequestMethodTypes.POST, body: requestBody });
    const content = await response.blob();
    const buffer = await content.arrayBuffer();
    resp = AreaPageListingResponse.decode(new Uint8Array(buffer));
  } catch (error: any) {
    console.error('Failed to fetch area page listings: %s', error);
    resp = AreaPageListingResponse.fromPartial({}); // FIXME: create error response type
  }

  return resp;
}
